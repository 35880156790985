export default {
  green: 'var(--color-green)',
  blue: 'var(--color-blue)',
  yellow: 'var(--color-yellow)',
  bhgGrey: 'var(--color-bhg-grey)',
  vividRed: 'var(--color-red-vivid)',
  neutralGrey: 'var(--color-neutral-grey)',
  fuchsia: 'var(--color-fuchsia)',
  lightPurple: 'var(--color-light-purple)',
  khaki: 'var(--color-khaki)',
  cyan: 'var(--color-cyan)',
  navyBlue: 'var(--color-navy-blue)',
  lightpurple: 'var(--color-light-purple)',
};
