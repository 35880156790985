/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */
import store from '@/store';

export default {
  beforeEach: async (to, from, next) => {
    const token = await store.dispatch('AuthenticationStore/refreshAccessToken');
    const user = store.getters['UserModule/getUser'];
    if (token && !user) {
      await store.dispatch('getProviderUser');
    }

    if (to.name === 'logout') {
      store.dispatch('AuthenticationStore/logout');
    } else if (!store.getters['AuthenticationStore/getUser']) {
      store.dispatch('AuthenticationStore/login');
    } else {
      next();
      await store.dispatch('finishLoading');
    }
  },
};
