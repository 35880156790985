/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */
import moment from 'moment-timezone';

const dateFilters = {
  toDate(date) {
    if(moment(date, true).isValid()) {
      const utcDate = moment(date, true).utc();
      return utcDate.format('MM/DD/YYYY');
    }
    return '';
  },
  toDateTime(date) {
    if(moment(date, true).isValid()) {
      const utcDate = moment(date, true).utc();
      return utcDate.format('MM/DD/YYYY h:mm A');
    }
    return '';
  },
  toDateTimeEDT(date) {
    if(moment(date, true).isValid()) {
      const edtDate = moment(date, true).tz('America/New_York');
      return edtDate.format('MM/DD/YYYY hh:mm:ss A z');
    }
    return '';
  },
};

export default dateFilters;
