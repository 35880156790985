/**
 * @typedef {{
  *  accountDetail: AccountDetail;
  *  financialSummary: FinancialSummary;
  * }} AccountSummary
  *
  * @typedef {{
  *  accountName: string;
  *  parentAccount: string;
  *  partnerPlatform: string;
  *  address: Address;
  *  phone: string;
  * }} AccountDetail
  *
  * @typedef {{
  *  accountId: string;
  *  accountName: string;
  *  reserveRequirements: ReserveRequirements;
  *  reserveAccountFunding: ReserveAccountFunding;
  *  operatingAccountFunding: OperatingAccountFunding;
  *  buyoutDetails: BuyoutDetails;
  * }} FinancialSummary
  *
  * @typedef {{
  *  totalLoansOutstanding: number;
  *  commitmentLine: number;
  *  reserveAccountBalance: number;
  *  reserveAccountBalanceRequirement: number;
  *  reserveExcessAmount: number;
  * }} ReserveRequirements
  *
  * @typedef {{
  * sumOfLoanDisbursals: number;
  * sumOfManualDeposits: number;
  * }} ReserveAccountFunding
  *
  * @typedef {{
  * sumofHCPPayouts: number;
  * }} OperatingAccountFunding
  *
  * @typedef {{
  * sumOfBuyoutAmounts: number;
  * undisbursedBuyoutAmounts: number;
  * }} BuyoutDetails
  *
  * @typedef {{
  *  address1: string;
  *  address2: string;
  *  city: string;
  *  state: string;
  *  zipcode: string;
  * }} Address
  */

/** @type AccountSummary */
export const accountSummary = {
  accountDetail: {
    accountName: '',
    parentAccount: '',
    partnerPlatform: '',
    address: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
    },
    phone: '',
  },
  financialSummary: {
    accountId: '',
    accountName: '',
    reserveRequirements: {
      totalLoansOutstanding: 0,
      commitmentLine: 0,
      reserveAccountBalance: 0,
      reserveAccountBalanceRequirement: 0,
      reserveExcessAmount: 0,
    },
    reserveAccountFunding: {
      sumOfLoanDisbursals: 0,
      sumOfManualDeposits: 0,
    },
    operatingAccountFunding: {
      sumofHCPPayouts: 0,
    },
    buyoutDetails: {
      sumOfBuyoutAmounts: 0,
      undisbursedBuyoutAmounts: 0,
    },
  },
};
